import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96')
];

export const server_loads = [0,18,3,20];

export const dictionary = {
		"/(website)": [~82,[18],[19]],
		"/(app)/_prototypes/action-plan": [38,[3]],
		"/(app)/_prototypes/maturity-assessment": [39],
		"/(app)/admin": [40,[3,7]],
		"/(app)/admin/library": [41,[3,7,8]],
		"/(app)/admin/library/emission-activities": [42,[3,7,8]],
		"/(app)/admin/library/initiatives": [43,[3,7,8]],
		"/(app)/admin/organisations": [44,[3,7]],
		"/(website)/appointments/[slug]": [~83,[18],[19]],
		"/(website)/blog": [~84,[18],[19]],
		"/(website)/blog/articles/[slug]": [~85,[18],[19]],
		"/(website)/company/_about-us": [87,[18],[19]],
		"/(website)/company/_careers": [88,[18],[19]],
		"/(website)/company/contact": [89,[18],[19]],
		"/(website)/company/subscription-terms": [90,[18],[19]],
		"/(website)/company/[slug]": [~86,[18],[19]],
		"/(backend)/healthz/liveness": [80],
		"/(backend)/healthz/readiness": [81],
		"/(website)/home/[slug]": [~91,[18],[19]],
		"/(app)/(auth)/invite/colleague": [21,[3]],
		"/(app)/(auth)/invite/email-sent": [22,[3]],
		"/(app)/(auth)/invite/not-found": [23,[3]],
		"/(app)/(auth)/invite/validate-otp": [24,[3]],
		"/(website)/legal/[slug]": [~92,[18],[19]],
		"/(website)/marketplace/[slug]": [~93,[18],[19]],
		"/(app)/(auth)/onboarding": [27,[3,5]],
		"/(app)/(auth)/onboarding/company-details": [28,[3,5]],
		"/(app)/(auth)/onboarding/invite-your-team": [29,[3,5]],
		"/(app)/(auth)/onboarding/your-profile": [30,[3,5]],
		"/(app)/(auth)/onboard": [~25,[3,4]],
		"/(app)/(auth)/onboard/organisation": [26,[3,4]],
		"/(app)/orgs": [45,[3,9]],
		"/(app)/orgs/[orgId]": [46,[3,9,10]],
		"/(app)/orgs/[orgId]/dashboards/demo": [47,[3,9,10]],
		"/(app)/orgs/[orgId]/emissions": [48,[3,9,10,11]],
		"/(app)/orgs/[orgId]/emissions/activity-data": [49,[3,9,10,11]],
		"/(app)/orgs/[orgId]/emissions/reports": [51,[3,9,10,11]],
		"/(app)/orgs/[orgId]/emissions/report": [50,[3,12]],
		"/(app)/orgs/[orgId]/onboard/finding-your-coach": [52,[3,9]],
		"/(app)/orgs/[orgId]/onboard/meet-your-coach": [53,[3,9]],
		"/(app)/orgs/[orgId]/onboard/user": [54,[3,9]],
		"/(app)/orgs/[orgId]/pathways/b-corp": [56,[3,9,10]],
		"/(app)/orgs/[orgId]/pathways/net-zero": [57,[3,9,10,13]],
		"/(app)/orgs/[orgId]/pathways/net-zero/roadmap": [58,[3,9,10,13]],
		"/(app)/orgs/[orgId]/pathways/net-zero/targets": [59,[3,9,10,13]],
		"/(app)/orgs/[orgId]/pathways/net-zero/targets/report": [60,[3,14]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan": [61,[3,9,10,15]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan/roadmap": [62,[3,9,10,15]],
		"/(app)/orgs/[orgId]/pathways/ppn-carbon-reduction-plan/targets": [63,[3,9,10,15]],
		"/(app)/orgs/[orgId]/pathways/[trackId]/prioritization": [55,[3,9,10]],
		"/(app)/orgs/[orgId]/plan": [64,[3,9,10,16]],
		"/(app)/orgs/[orgId]/policies": [65,[3,9,10]],
		"/(app)/orgs/[orgId]/policies/[slug]": [66,[3,9,10]],
		"/(app)/orgs/[orgId]/reporting/b-corp": [67,[3,9,10]],
		"/(app)/orgs/[orgId]/reporting/b-corp/generate": [68,[3,9,10]],
		"/(app)/orgs/[orgId]/reporting/b-corp/maturity": [69,[3,9,10]],
		"/(app)/orgs/[orgId]/reporting/our-story": [70,[3,9,10]],
		"/(app)/orgs/[orgId]/value-chain": [71,[3,9,10,17]],
		"/(app)/orgs/[orgId]/value-chain/downstream": [72,[3,9,10,17]],
		"/(app)/orgs/[orgId]/value-chain/products-and-services": [73,[3,9,10,17]],
		"/(app)/orgs/[orgId]/value-chain/suppliers": [74,[3,9,10,17]],
		"/(app)/orgs/[orgId]/value-chain/upstream": [75,[3,9,10,17]],
		"/(website)/pricing/[slug]": [~94,[18],[19]],
		"/(app)/public/b-impact-assessment/[reportId]": [76,[3]],
		"/(app)/public/our-sustainability-story/[orgId]": [77,[3]],
		"/(app)/public/sustainability/initiatives": [78,[3]],
		"/(app)/public/sustainability/initiatives/[initiativeSlug]": [79,[3]],
		"/(app)/(auth)/session/error": [31,[3,6]],
		"/(app)/(auth)/session/expired-link": [32,[3,6]],
		"/(app)/(auth)/session/link-accounts": [33,[3,6]],
		"/(app)/(auth)/session/login": [34,[3,6]],
		"/(app)/(auth)/session/signin": [35,[3,6]],
		"/(app)/(auth)/session/signup": [36,[3,6]],
		"/(app)/(auth)/session/verify-email": [37,[3,6]],
		"/(website)/solutions/[slug]": [~95,[18],[19]],
		"/(website)/supply-chain/[slug]": [~96,[20]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';